import { emptyProfile, IIdHelper, IProfile } from '../provider/Profile/profileProvider'
import { Dispatch, SetStateAction, useState } from 'react'
import useAsyncEffect from './effects/async'
import { loaded } from '../utils/process'
import { fetchApi } from '../utils/fetcher'
import { useEthereumProvider } from '../provider/Ethereum/ethereumProvider'

export interface IUseProfile extends IProfile {
  isLoading: boolean
  setProfile: Dispatch<SetStateAction<IProfile>>
}

interface IProps extends IIdHelper {}

export const useProfile = ({ address }: IProps): IUseProfile => {
  const [data, setData] = useState<IProfile>(emptyProfile)
  const [isLoading, setIsLoading] = useState(true)

  const wallet = useEthereumProvider()

  /* TODO: Periodically update profile state (e.g. when the blockedUsers
    changes; when the user profile details change because of a blockchain
    event; when their onboarding status changes) */

  useAsyncEffect(async () => {
    if (!address) {
      setData(emptyProfile)

      return
    }

    void loaded(async () => {
      setData({
        ...emptyProfile,
        address: address.toLowerCase() as address,
        address_: [address.toLowerCase() as address],
      })

      // TODO: Make this a single query (authed /profile/:authedAddress GET
      // fetch – update frontend fetching api not to use POST when `data`
      // parameter is present already || OR just make all requests be built on POST.)
      const data_: IProfile = await fetchApi(`/profile/${address.toLowerCase()}`)
      const blockData: Pick<IProfile, 'blockedUsers'> =
        wallet.isAuthed && wallet.account?.toLowerCase() === data_.address && data_.isOnboarded
          ? await loaded(async () => await fetchApi(`/settings`, {}))
          : []

      const data = { ...data_, blockedUsers: blockData?.blockedUsers ?? [] }

      setData({
        ...(data ?? emptyProfile),
        address: data.address_?.[0] ?? address.toLowerCase(),
        address_: data.address_?.length ? data.address_ : [address.toLowerCase()],
      } as IProfile)
    }, setIsLoading)
  }, [address])

  return { ...data, isLoading, setProfile: setData }
}
